.offersDiv {
  display: flex;
  justify-content: space-between;
  padding-inline: 5%;
  overflow: hidden;
  img {
    width: 24.5%;
    box-sizing: border-box;
    cursor: pointer;
  }
}

.offersWrapper h1 {
  color: #218796;
  font-family: "Inter Medium";
  font-size: 44px;
  text-align: center;
}
.offersWrapper .parag {
  color: #212121;
  font-size: 16px;
  font-family: "Inter Light";
  width: 60%;

  text-align: center;
}
.formTitle {
  color: #218796;
  font-family: "Inter Medium";
  font-size: 32px;
  text-align: center;
}
.popUpForm {
  width: 90%;
  position: fixed;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  box-sizing: border-box;
  align-items: center;
  left: 5%;
  top: 5%;
  border-radius: 8px;
  height: 90vh;
  background-color: #fff;
  box-shadow: 1px 1px 25px -20px #000000;
  svg {
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 25px;
  }
}
.fields {
  display: flex;
  gap: 2%;
  margin-top: 45px;
  row-gap: 30px;
  padding-inline: 20%;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.inputs {
  width: 49% !important;
}
.birthday {
  width: 49%;
  position: relative;
  p {
    position: absolute;
    top: -25px;
  }
}
.birthday .inputs {
  width: 100% !important;
}
.gender {
  display: flex;
  align-items: center;
  gap: 10px;
}

.submitForm {
  background-color: #218796;
  width: 20%;
  position: absolute;
  left: 50%;
  bottom: 5%;
  transform: translateX(-50%);
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
}
