#my-visa-application{
    padding-top: 150px;
    margin: 0 5%;
}
.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    height: calc(100vh - 150px);
}