.header{
    width: 100%;
    height: auto;
    background-color: transparent;
    height: auto;
    margin-bottom: 0px;
    position: fixed;
    top: 0;
    transition: 1s;
    z-index: 10;
    max-width: 100%;
}
.log-in-header{
    display: flex;
    padding-inline: 5%;
    justify-content: right;
    font-size: 14px;
    align-items: center;
    height: 50px;
    flex-wrap: wrap;
}
.log-in-header p{
    color: #000;
    
}

.log-in-header .sign{
  width: 10%;
  margin-left: 2%;
  margin-right: 0;
}
.header-logo{
    width: 80%;
}
.header-language{
    margin-right: 4%;
}
.green-text{
    color: #75C19F;
    
}
.bottom-line{
    width: 100%;
    height: 1px;
    background-color: #ECECEC;
   
}
.main-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 5%;
}
.header-links{
    display: flex;
    justify-content: right;
    width: 50%;
    align-items: center;
    
}
.header-link{
    text-decoration: none;
    color: #000;
    margin-right: 8%;
}
.text-transition{
    transition: 1s;

}
.dissapear{
    margin-right: 0%;
    display: none;
}

.header-button{
    background-color: #75C19F;
    border-radius: 25px;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    transition: 0.4s;
}
.header-button:hover{
  scale: 1.009;
}
.borgir{
    display: none;
}
@media screen and (max-width: 768px) {
  .main-header{
    display: block;
  }
  .log-in-header{
    display: none;
  }
  .header-logo{
    width: 60%;
    margin-top: 5px;
    margin-left: 20px;
  }
  .header{
    height: 90px;
    overflow: hidden;
    position: fixed;
    background-color: #fff!important;
    top: 0;
    max-width: 100%;
    padding: 0;
    button{
      margin-left: 15px;
      margin-top: 10px;
    }
  }
  .main-header{
    padding-inline: 0;
  }
  .header-link p{
    padding-left: 5%;
  }
  .header-links a:last-child{
    margin-right: 0%;
    display: block;
}

  .header-links{
    display: block;
    margin-top: 40px;
    width: auto;
  }
  .sign-up-link{
    display: block;
  }
  .dissapear{
    display: block
  }
  .borgir{
    display: block;
    position: absolute;
    right: 40px!important;
    top: 30px!important;
  }
  .hajj{
    margin-left: -10px;
  }
}