.hotel-view{
    margin: 0 5%;
    padding-top: 150px;
}
.main-gallery{
    display: flex;
    align-items: center;
    .side-bar{
        width: 25%;
        .filter-card{
            width: 100%;
            height: 50%;
            background-color: #fff;
            -webkit-box-shadow: 0px 0px 15px -12px #000000; 
            box-shadow: 0px 0px 15px -12px #000000;
            border-radius: 10px;
            animation: fadeInLeft 0.5s linear;
            padding: 3%  0; 
            margin: 20px 5px 0 0;
            .logo{
                width: 100%;
                display: flex;
                margin: 30px 0;
                justify-content: center;
                img{
                    width: 50%;
                    transition: 0.4s;
                    
                }
                img:hover{
                    scale: 1.1;
                }
            }
        }
    }
    .hotel-gallery{
        width: 75%;
        //display: flex;
        margin: 0 5px; 
        .hotel-rating-info{
            display: flex;
            justify-content: space-between;
        }
        .gallery{
            display: flex;
        }
        .qabja_icon{
            width: 24px;
        }
    }
}
.cover-img{
    width: 80%; 
    height: 620px;
}
.more-img{
    width: 20%;
    height: 620px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.table-head{
    background-color: #218796;
    color: white;
}
.white{
    color: white !important;
}
.book_now_in_table{
    background-color: #75C19F;
    border-radius: 25px;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 10px 100px;
    text-align: center;
    cursor: pointer;
    transition: 0.4s;
    white-space: nowrap;
}
.more_img{
    
    background: rgba(0,0,0, 0.5);
    color: white;
    position: absolute;
    display: flex;
    top: 0px;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}
.close_date_range{
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 768px) {
    .main-gallery{
        display: block;
        .side-bar{
            width: 100%;
        }
        .gallery{
            width: 100%;
        }
        .cover-img{
            width: 100%;
        }
    }
}