.sign-up{
    padding-top: 125px;
    margin-left: auto;
    margin-right: auto;
    background-color: #F8F8F8;
    height: 100vh;
    justify-content: center;
    display: flex;
    width: 100%;
   
}
.signup-form{
    width: 30%;
    margin-top: 55px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    form{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
    }
    img{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }
}
.sign-mosque{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 35%;
}
.signup-fields{
    background-color: #fff;
}
.signup-button{
    background-color: #75C19F;
    width: 100%;
    color: #fff;
    border: none;
    height: 50px;
    border-radius: 38px;
    margin-top: 20px;
    font-size: 18px;
}
.already-member{
    font-size: 16px;
    font-family: "Inter Medium";
    color: #000;
}
.already-member .alrmb {
    color: #75c19f;
}
@media screen and (max-width: 768px){
    .sign-up{
        margin-top: 50px;
    }
    .signup-form{
        padding-inline: 10%;
        width: 80%;
    }
    .sign-mosque{
        width: 100%;
    }
}