.add-hotel-input-wrapper{
    margin-top: 25px;
}
.add-hotel-input-wrapper .input-rooms{
    width: 100%!important;
}
.add-hotel-input-wrapper{
    padding-inline: 5%;
}
.addRooms{
    margin-top: 10%;
}
#rooms-step{
    display: none;
}

.klas .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root{
    height: 150px!important;
}
.klas .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input{
    position: absolute;
    top: 20px;
}
.add-steps{
    display: flex;
    margin-bottom: 50px;
    padding-inline: 5%;
    #first-step{
    width: 33.3%;
    border: 0.5px solid #C7C7C7;
    display: flex;
    align-items: center;
    justify-content: space-around;
    }
    #second-step{
    width: 33.3%;
    border: 0.5px solid #C7C7C7;
    display: flex;
    align-items: center;
    justify-content: space-around;
    }
    #third-step{
    width: 33.3%;
    border: 0.5px solid #C7C7C7;
    display: flex;
    align-items: center;
    justify-content: space-around;
    }
    .steps-header{
        color: #1E1E1E;
        font-family: "Inter Bold";
        font-size: 14px;
    }
    .steps-parag{
        color: #1E1E1E;
        font-family: "Inter Medium";
        font-size: 14px;
    }
    .steps-parag:last-child{
        margin-top: -10px;
    }
    .number-steps{
        font-size: 24px;
        color: #75C19F;
        font-family: "Inter Bold";
    }
  

}
#file-input{
    display: none;
}
#file-input2{
    display: none;
}
.file-upload-wrapper .big-input{
    text-align: center;
    padding-top: 7.5%;
    border: 1px dashed #C7C7C7;
    width: 35%;
    height: 15vh;
    padding-bottom: 0%;
    cursor: pointer;
}
.file-upload-wrapper{
    display: flex;
    justify-content: left;
    margin-top: -5px;
    width: 80%;
}
.vertical-line{
    width: 1px;
    height: 22vh;
    margin-left: 5%;
    background-color: #c7c7c7;
}
.file-upload-wrapper .small-input{
    text-align: center;
    padding-top:5%;
    border: 1px dashed #C7C7C7;
    width: 25%;
    height: 12vh;
    padding-bottom: 0%;
    margin-left: 5%;
    cursor: pointer;
}
.desc-input{
    display: flex;
    justify-content: space-between;
    width: 55%;
    padding-left: 5%;
}
.desc-input p{
    font-size: 12px;
    color: #868686;
}
.next-button{
    width: 20%;
    height: 40px;
    background-color: #75C19F;
    color: #fff;
    border: none;
    border-radius: 25px;
    position: absolute;
    right: 5%;
    bottom: 2%;
    transition: 0.4s;
}
.next-button:hover{
    scale: 1.009;
    transform: translateY(-2px);
}
.back-button:hover{
    scale: 1.009;
    transform: translateY(-2px);
}
.back-button{
    width: 20%;
    transition: 0.4s;
    height: 40px;
    background-color: #F8F8F8;
    color: rgb(255, 96, 96);
    border: 1px solid red;
    border-radius: 25px;
    position: absolute;
    right: 27%;
    bottom: 2%;
}
.add-room-txt{
        padding-left: 5%;
        color: #5F5F5F;
        font-size: 16px;
        font-family: "Inter Medium";
 }
.input-step-half{
    display: flex;
    width: 100%;
    .add-hotel-input-wrapper{
        width: 50%;
        margin-top: 25px;
        padding-left: 5%;
    }
    .add-hotel-input-wrapper:last-child{
        padding-left: 0;
        
    }
    .input-rooms-half{
        width: 100%!important;
    }
}
.reservo{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.reservo p{
    font-size: 18px;
    color: #171725;
    font-family: "Inter Bold";
}
.add-hotels{
    background-color: #75C19F;
    border: none;
    border-radius: 25px;
    font-size: 14px;
    color: #fff;
    font-family: "Inter Bold";
    width: 20%;
    height: 40px;
}