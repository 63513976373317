.visa-application {
    margin-top: 150px;
    display: flex;
    justify-content: left;
    flex-wrap: nowrap;
    height: calc(100vh - 150px);
}

.form-control {
    margin-top: 20px;
    width: 100%;
}

.visa-leftmenu {
    width: 40%;
    text-align: center;
}

.input_error {
    border-bottom: 3px solid red !important;
    border-radius: 5px;
}

.input_success {
    border-bottom: 3px solid green !important;
    border-radius: 5px;
}

.personal_info {
    padding-top: 15px;
    text-align: left;
    padding-left: 65px;
}

.css-1arijsr-MuiSnackbar-root {
    top: 160px !important;
}

.visa-steps {
    width: calc(100% - 20%);
    padding-inline: 10%;
    background-color: #f8f8f8;
    // height: 100vh;
    display: flex;
    // align-items: center;
    padding-top: 5%;
    overflow: auto;
}
.residential-button , .commercial-button{
    accent-color:#75C19F ;

    margin-bottom: -20px;

}

.fgoback {
    color: #868686;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
}

.fgoback img {
    margin-bottom: -2px;
    margin-right: 5px;
}

.steps {
    width: 100%;
}

.visa-header {
    width: 100%;
}

.form-control:first-child {
    margin-top: 20%;
}

.visa-header {
    font-size: 32px;
    color: #218796;
    font-family: "Inter Semi Bold";
}

.application-line {
    width: 100%;
    height: 1px;
    background-color: #E1E1E1;
    margin-top: 100px;

}

.username {
    font-size: 20px;
    color: #000;
    font-family: "Inter Medium";
    margin-top: -10px;
    // padding-left: 12%;
}

.username-text {
    color: #868686;
    font-size: 12px;
    font-family: "Inter Light";
    margin-top: 50px;
    padding-left: 12%;
}

.step-continue {
    width: 100%;
    color: #fff;
    font-size: 16px;
    border: none;
    background-color: #75C19F;
    border-radius: 38px;
    height: 45px;
    margin-top: 35px;
}

#getFile {
    display: block;
}

#file-upload-button {
    display: none;
}

.file-text {
    font-family: "Inter Light";
    color: #868686;
    font-size: 12px;
    width: 100%;
}

#image-upload {
    width: 100%;
    height: 50px;
    border: 0.5px solid #636363;
    border-radius: 4px;
    background-color: transparent;
    color: #000;
    text-align: left;
    font-size: 16px;
    font-family: "Inter Medium";
    padding: 0px 0px 55px 10px;
}
form{
    width: 100%;
}
.input-visa {
    width: 100%;

}

.input-visaa {
    width: 100% !important;
}

.basic-visa-wrap {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1%;

    .input-visa {
        width: 49.5%;
    }

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.input-visa {
    margin-bottom: 20px !important;
}

#secondstep {
    display: none;
    margin-top: 10%;
}

#thirdstep {
    display: none;
    margin-top: 10%;
}

#fourthstep {
    display: none;
    margin-top: 10%;
}

#fifthstep {
    display: none;
    margin-top: 10%;
}

#sixthstep {
    display: none;
    margin-top: 10%;
}

#seventhstep {
    display: none;
    margin-top: 10%;
}

#selectfile {
    width: 200px;
}

#firststep {
    display: block;
}

.visa-leftmenu {
    display: block;
}

.visa-steps button {
    cursor: pointer;
}

#cart {
    min-height: 100vh;
    display: none;
    justify-content: left;
    background-color: #f8f8f8;
    width: 100%;
    height: 100%;
    padding-inline: 5%;
    align-items: baseline;
    padding-top: 50px;
    flex-wrap: wrap;

    .input-visa {
        background-color: #fff;
    }

    padding-bottom: 2%;
}

.cart-header {
    font-family: "Inter Semi Bold";
    font-size: 32px;
    color: #000;
}

.cart-paragraph {
    font-family: "Inter Light";
    font-size: 16px;
    color: #868686;

}

.cart-privacy {
    font-family: "Inter Light";
    font-size: 14px;
    color: #868686;

    label {
        text-decoration: underline;
    }

}

.cart-info {
    max-width: 45%;
    height: auto;
}

.cart-payment {
    width: 45%;
    height: auto;
    margin-left: 5%;

    .order-info {
        background-color: #fff;
        width: 100%;
        padding-inline: 5%;
        border-radius: 4px;
        border: 1px solid #C7C7C7;
        padding-bottom: 5%;

    }

    .pay-button {
        width: 110%;
        color: #fff;
        background-color: #75C19F;
        border-radius: 4px;
        border: none;
        height: 50px;
        padding-inline: 5%;
        font-size: 20px;
    }

    .credit-card {
        background-color: #fff;
        padding-inline: 5%;
        border-radius: 4px;
        border: 1px solid #C7C7C7;
        width: 100%;
        display: flex;
        margin-top: 5%;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 5%;

        .cart-subheader {
            width: 40%;
        }

        img {
            width: 135px;
            height: 39px;
        }

        .card-number {
            width: 60%;

        }

        .card-cvc {
            width: 20%;

        }
    }

    .cart-header {
        text-align: left;
    }

    .price {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #E1E1E1;
    }

    .price:last-child {
        border: none;
    }

    .cart-subheader {
        font-family: "Inter Medium";
        color: #000000;
        font-size: 16px;
    }

    .green {
        color: green;
        font-family: "Inter Bold";
    }

}

#second-step-change {
    display: none;
}

#third-step-change {
    display: none;
}

#fourth-step-change {
    display: none;
}

#thirdstep-step-change {
    display: none;
}

#fifth-step-change {
    display: none;
}

#sixth-step-change {
    display: none;
}

#seventh-step-change {
    display: none;
}

.terms {
    color: #000000;
    font-size: 20px;
    font-family: "Inter Medium";
}

.terms-wrapper {
    height: 50vh;
    overflow: scroll;
}

.terms-wrapper p {
    color: #868686;
    font-size: 16px;
    font-family: "Inter Light";
}

.not-eligible {
    color: #868686;
    font-size: 12px;
    font-family: "Inter Light";
}

.medical-name {
    font-size: 18px;
    color: #000;
    font-family: "Inter Medium";
    text-align: center;
}

.medival-visa {
    color: #000;
    font-family: "Inter Medium";
    font-size: 20px;

}

.medical-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.medical-companies {
    width: 28%;
    background-color: #fff;
    margin-top: 3%;
    padding: 2%;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
}

.medical-companies img {
    width: 80%;
    padding-inline: 10%;
    height: 100px;
}

@media screen and (max-width: 768px) {
    .visa-application {
        // display: flex;
        margin-top: 100px;
        flex-wrap: wrap;
    }

    .terms-wrapper {
        height: 75vh;
    }

    .medical-wrap {
        display: block;
        width: 100%;
    }

    .medical-companies {
        width: 100%;

        img {
            object-fit: scale-down;
        }
    }

    .visa-leftmenu {
        width: 100%;
        height: auto;

    }

    .step-continue {
        margin-bottom: 10%;
    }

    #cart {
        flex-wrap: wrap;
        padding: 0;

        .cart-info {
            width: 100%;
            max-width: 90%;
            padding-inline: 5%;
        }

        .cart-payment {
            width: 90%;
            margin-left: 0;
            margin-top: 10%;
        }

        .order-info {
            padding-inline: 5%;

        }

        .credit-card {
            padding-inline: 5%;

        }

        .cart-privacy {
            padding-inline: 5%;

        }

        .pay-button {
            margin-inline: 5%;
            width: 100%;
            margin-bottom: 15%;
        }
    }

    .visa-header {
        padding-left: 0%;

    }

    .input-visa {
        width: 100% !important;
    }

    #cart .visa-steps {
        -webkit-box-shadow: 0px -6px 11px -4px #000000;
        box-shadow: 0px -6px 11px -14px #000000;
        width: 100%;
    }

    .application-line {
        display: none;
    }

    .username-text {
        display: none;
    }

    .username {
        display: none;
    }

    form {
        padding-top: 5%;
    }
}

.block {
    display: block;
}

.no-block {
    display: none;
}

.boxShadow {
    box-shadow: 0px 0px 10px 0px #18ab12;
}