.admin-dashboard{
    padding-top: 120px;
   width: 100%;
    height: auto;
    display: flex;
    gap: 2.5%;
    background-color: #f8f8f8;
 }
 .admin-sidebar{
    height: 180vh;
        border-radius: 8px;
   
    width: 25%;
    background-color: #fff;
 }
 .active-cat{
    background-color: #cffbe9;
 }
 .admin-content{
    background-color: #fff;
    border-radius: 8px;
    padding-inline: 2.5%;
    padding-top: 1%;
    width: 65%;
    box-shadow: 0px 1px 4px #15223214;

 }
.first-cat{
   border-top-right-radius: 8px;
}
.sidestick{
   position: fixed;
   width: 25%;
}
 .sidebar-cat{
    display: flex;
   border-bottom: 2px solid #f8f8f8;
    justify-content: left;
    gap: 25px;
    width: 80%;
    
    height: 60px;
    align-items: center;
    padding-inline: 10%;
   
    img{
        width: 24px;
        height: 24px;
    }
    .green-sidebar{
        font-size: 14px;
        font-family: "Inter Medium";
        color: #75C19F;
        margin: 0;
        padding: 0;
    }
    p{
        font-size: 14px;
        font-family: "Inter Medium";
        margin: 0;
        padding: 0;
    }
    &:hover{
        background-color: #EAF6F1;
        scale: 1.02;
    }
 }
td{
   text-align: center!important;
}
 table.customTable {
    width: 65vw;
    background-color: #FFFFFF;
    border-collapse: collapse;
    border-width: 0px;
    border-color: #FFFFFF;
    border-style: solid;
    color: #000000;
  }
  table.customTable th{
    font-size: 12px;
  }
  table.customTable td{
    font-size: 14px;
  }
  table.customTable td, table.customTable th {
    border-width: 0px;
    border-color: #FFFFFF;
    border-style: solid;
    text-align: left;
    padding: 10px;
    color: #000;
    padding-left: 2.5%;
  }
  
  table.customTable thead {
    background-color: #2187961A;
    color: #000;
  }
  .add-hotel{
   width:60%;
   height: 101vh;
   background-color: #fff;
   border: solid 1px #f8f8f8;
   box-shadow: 0px 1px 4px #15223214;
   position: fixed;
   z-index: 333333333;
   border-radius: 8px;
   top: 0;
   margin-top: -10px;
   right: 0;
  }
  .hija{
   width: 100%;
   height: 100vh;
   background-color: #000;
   opacity: 0.2;
   position: fixed;
   top: 0;
   z-index: 333333332;
  }
  