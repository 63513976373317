
.hotel-info-wrap img{
    width: 20px;
    height: 20px;
}
td img{
    width: 100%;
    object-fit: cover;
}
th{
    text-align: center!important;
}
.edit-hotel{
    color: #75C19F!important;
    text-decoration: underline;
    cursor: pointer;
    text-align: center!important;
}
.edit-hotel p:hover{
    transform: translateY(-2px);
}
.edit-hotel p{
    transition: 0.4s;
}