
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.input-visa{
        margin-bottom: 20px!important;
}

.singlecart-wrapper{
    padding-top: 8%;
    background-color: #f8f8f8;
}
#single-cart{
    padding-top: 15%;
    min-height: 100vh;
    display: flex;
    justify-content: left;
    background-color: #f8f8f8;
    width: 90%;
    height: 100%;
    padding-inline: 5%;
    align-items: baseline;
    padding-top: 50px;
    flex-wrap: wrap;
    .input-visa{
        background-color: #fff;
    }
    padding-bottom: 2%;
}

.cart-header{
    font-family: "Inter Semi Bold";
    font-size: 32px;
    color: #000;
}
.cart-paragraph{
    font-family: "Inter Light";
    font-size: 16px;
    color: #868686;
    
}
.cart-privacy{
    font-family: "Inter Light";
    font-size: 14px;
    color: #868686;
    label{
        text-decoration: underline;
    }
    
}
.cart-info{
    max-width: 45%;
    height: auto;
}
.cart-payment{
    width: 45%;
    height: auto;
    margin-left: 5%;
    .order-info{
        background-color: #fff;
        width: 100%;
        padding-inline: 5%;
        border-radius: 4px;
        border: 1px solid #C7C7C7;
        padding-bottom: 5%;
        
    }
    .pay-button{
        width: 110%;
        color: #fff;
        background-color: #75C19F;
        border-radius: 4px;
        border: none;
        height: 50px;
        padding-inline:5%;
        font-size: 20px;
    }
    .credit-card{
        background-color: #fff;
        padding-inline: 5%;
        border-radius: 4px;
        border: 1px solid #C7C7C7;
        width: 100%;
        display: flex;
        margin-top: 5%;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 5%;
        .cart-subheader{
            width: 40%;
        }
        img{
            width: 135px;
            height: 39px;
        }
        .card-number{
            width: 60%;

        }
        .card-cvc{
            width: 20%;

        }
    }
     .cart-header{
        text-align: left;
    }
    .price{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #E1E1E1;
    }
    .price:last-child{
        border: none;
    }
    .cart-subheader{
        font-family: "Inter Medium";
        color: #000000;
        font-size: 16px;
    }
    .green{
        color: green;
        font-family: "Inter Bold";
    }
   
}
@media screen and (max-width: 768px){
    .visa-application{
        display: block;
        margin-top: 100px;
    }
    .terms-wrapper{
        height: 75vh;
    }
    .medical-wrap{
        display: block;
        width: 100%;
    }
    .medical-companies{
        width: 100%;
        img{
            object-fit: scale-down;
        }
    }
    .visa-leftmenu{
        width: 100%;
        height: 10vh;
     
    }
    .step-continue{
        margin-bottom: 10%;
    }
    #single-cart{
        flex-wrap: wrap;
        padding: 0;
        width: 100%;

        .cart-info{
            width: 100%;
            max-width: 90%;
            padding-inline: 5%;
        }
        .cart-payment{
            width: 90%;
            margin-left: 0;
            margin-top: 10%;
        }
        .order-info{
            padding-inline: 5%;

        }
        .credit-card{
            padding-inline: 5%;

        }
        .cart-privacy{
            padding-inline: 5%;

        }
        .pay-button{
            margin-inline: 5%;
            width: 100%;
            margin-bottom: 15%;
        }
    }
    .visa-header{
        padding-left: 0%;

    }
    .input-visa{
        width: 100%!important;
    }
    #single-cart
    .visa-steps{
        -webkit-box-shadow: 0px -6px 11px -4px #000000; 
box-shadow: 0px -6px 11px -14px #000000;
width: 100%;
    }
    .application-line{
        display: none;
    }
    .username-text{
        display: none;
    }
    .username{
        display: none;
    }
    form{
        padding-top: 5%;
    }
}