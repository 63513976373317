.search-the-hotels{
    padding-top: 150px;
}

.home-searchi{
    width: 25%;
    height: 50%;
    background-color: #fff;
    margin-top: 1.8%;
    -webkit-box-shadow: 0px 0px 15px -12px #000000; 
    box-shadow: 0px 0px 15px -12px #000000;
    border-radius: 10px;
    position: fixed;
    padding-inline: 2.5%;
    padding-top: 3%;
    padding-bottom:3%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    animation: fadeInLeft 0.5s linear;
    align-items: flex-start;
}
.input-search{
    width: 100%!important;
}
.hotelet{
    margin-left: 32%;
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    .hotels-wrapper-done{
    width: 47%;
    margin-top: 2.5%;
    }
    .card{
        width:100%;
    }
    .cardImg{
        width: 100%;
        height: 40vh;
        min-width: 100%;
    }
   
}
.hotelet-admin{
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    .hotels-wrapper-done{
    width: 47%;
    margin-top: 2.5%;
    }
    .card{
        width:100%;
    }
    .cardImg{
        width: 100%;
        height: 40vh;
        min-width: 100%;
    }
   
}
.search-buttoni{
    background-color: #75C19F;
    border-radius: 25px;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 10px 100px 10px 100px;
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 25px;
    cursor: pointer;
    transition: 0.4s;
}
.search-buttoni:hover{
    scale: 1.009;
}

@media screen and (max-width: 768px){
    .home-searchi{
        width: 95%;
        position: relative;
    }
    .hotelet{
        margin-left: 0;
        flex-direction: column;
        .hotels-wrapper-done{
            width: 100%;
        }
    }
}
