.Home{
    height: auto;
    width: 100%;
    background-color: #F8F8F8;
    animation: opacity 1s linear;
}
.home-search{
    width: 60%;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-shadow: 0px 0px 15px -12px #000000; 
    box-shadow: 0px 0px 15px -12px #000000;
    border-radius: 10px;
    position: relative;
    margin-top: -100px;
    padding-inline: 5%;
    padding-top: 3%;
    padding-bottom:3%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    animation: fadeInRight 0.5s linear;
    align-items: flex-start;
}
.landing-home{
    width: 100%;
}
.landing-home img{
    background-size: cover;
    background-position: center;
    background-attachment: fixed; 
}
.date{
    width: 49%!important;
    background-color: transparent!important;
    position: relative;
    z-index: 2
}
.fullwidthi{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -30px;

    img{
        width: 64px;
        height: 64px;
        transition: 0.4s;
        
    }
    img:hover{
        scale: 1.1;
    }
}

.testii{
    margin-left: -45px;
    width: 24px;
    margin-top: 15px;
    height: 24px;
}

.top-hidden-arrow{
    width: 49%!important;
    .MuiSvgIcon-root{
        display: none;
    }
}
a{
    text-decoration: none;
}
.text-transition{
    color: #000;
}
.checkbox-round{
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
    padding: 1%;
}
.search-button{
    background-color: #75C19F;
    border-radius: 25px;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 15px 150px 15px 150px;
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    // margin-top: -25px;
    cursor: pointer;
    transition: 0.4s;
}
.search-button:hover{
    scale: 1.009;
}
.home-about-us-text{
    margin-top: 5%;
    padding-inline: 7%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.home-about-us-image{
    display: flex;
    align-items: center;
    justify-content: left;
    padding-inline: 5%;
    padding-top: 5%;
}
.home-about-us-image img{
    transition: 0.4s;
    width: 50%;
}
// .home-about-us-image img:hover{
//     scale: 1.009;
// }
.section-header{
    color: #218796;
    font-family: "Inter Medium";
    font-size: 44px;

}
.about-us-paragraph{
    color: #868686;
    font-size: 16px;
    width: 33%;

}
#sign-in{
    transition: 1s;
}
.checkbox-round:checked {
    background-color: #75C19F;
    transition: 1s;
}

.search-checkbox{
    display: flex;
    justify-content: left;
    width: 33%;
    align-items: center;
}

.transport-checkbox{
    margin-left: 20px;
}
.checkbox-image{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 100px;
}
.checkbox-image img{
    width: 64px;
    height: 64px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
.notvisible{
    width: 33%;
    height: 2px;
    visibility: hidden;
}
.search-type{
    color: #868686;
    font-size: 12px;

}
.search-selected{
    font-family: "Inter Medium";
    color: #000000;
}
.search-table{
    display: flex;
    justify-content: space-around;  
    width: 100%;
    flex-wrap: wrap;  
}
.search-row{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49.739%;
    border: 1px solid #E1E1E1;

}

.search-image{
    width: 32px;
    height: 32px;
    margin-right:20px;
}
.search-text{
    margin-top: 10px;
}
.hotels-home{
    padding-inline: 10%;
    word-wrap: break-word;
    width: 50%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
.hotels-header{
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
}
.hotels-wrapper{
    display: flex;
    justify-content: left;
    align-items: center;
    padding-inline: 5%;
    flex-wrap: wrap;
}
.hotels-image{
    width: 100%;
}
.hotel-home{
    width: 32%;
    margin-left: 2%;
}
.hotel-home:first-child{
    margin-left: 0;
}
.ratings{
    display: flex;
    justify-content: space-between;
}
.hotel-name{
    font-family: "Inter Bold";
    font-size: 20px;
    color: #000;
    font-weight: 700;
}
.hotel-info-wrap{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    margin-top: -25px;
}
.hotel-info{
    font-size: 18px;
    font-family: "Inter Light";
    color: #868686;

}
.small-logo{
    width: 20px;
    height: 20px;
}
.yjet{
    width: 30%;
    height: 30px;
    animation: fadeInRight 0.5s linear;

}

.ratings{
    height: 32px;
}
.displace-home{
    position: relative;
    font-size: 64px;
    font-family: "Displace Regular";
    color: #fff;
    margin-top: -28%;
    display: flex;
    width: 50%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.home-footer{
    margin-top: 5%;
}
.home-footer img{
    width: 100%;
    margin-bottom: -10px;

}
#date-hide{
    display: none;
}
#hide-date{
    display: none;
}
.home-footer button{
    background-color: #75C19F;
    border-radius: 25px;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 12px 100px 12px 100px;
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: -25px;
    cursor: pointer;
    transition: 0.4s;
}
.home-footer button:hover{
    background-color: #75C19F;
    scale: 1.009;
}
@media screen and (max-width:768px){
    .checkbox-image{
        display: block;

    }
    .search-checkbox{
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .search-table{
        width: 100%;
        padding: 0;
        margin: 0;
        display: block;
        margin-top: 20px;
    }
    .logopatext{
        margin-top: 20px;
        
    }
    .search-row{
        width:100%
    }
    .home-search{
        margin-top: -150px;
        padding-top: 7%;
        padding-bottom: 7%;
        width: 80%;
    }
    .search-button{
        background-color: #75C19F;
        border-radius: 25px;
        border: none;
        color: #fff;
        margin-top: 20px;
        font-size: 14px;
        padding: 10px 75px 10px 75px;
        position: relative;
        display: flex;
        margin-left: auto;
        margin-right: auto;
       
    }
    .landing-home{
        height: 35vh;
    }
    .home-about-us-text{
        display: block;
        text-align: center;
    }
    .about-us-paragraph{
        width: 100%;
    }
    .home-about-us-image{
        width:100%;
        padding-inline: 0;
    }
    .hotels-header{
        font-size: 24px;
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .hotels-wrapper{
        display: block;
        padding-inline: 0;
        max-width: 100%;
        margin: 0;
    }.hotel-home{
        width: 100%;
        padding-bottom: 5%;
        margin-left: 0;
    }
    .displace-home{
        margin: -400px 0 0 0;
        width: 100%;
        font-size: 44px;
        
    }
    .home-footer img{
        width: 100%;
        object-fit: cover;
        height: 60vh;
    }
    .home-footer Button{
        margin-top: 30px;
    }
    .home-about-us-image img{
        height: 17vh;
        object-fit: cover;
        padding-inline: 0;
    }
    .fullwidthi{
        width: 100%;
    }
    .top-hidden-arrow{
        width: 100%!important;
    }
    .date{
        width: 100%!important;
    }
    #date-hide{
        display: block;
    }
    #hide-date{
        display: block;
    }
}
@keyframes opacity{
    0% {
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes fadeInRight{
    from {
        transform: translateX(50px);
      }
    
      to {
        transform: translateX(0px);
      }
}
@keyframes fadeInLeft{
    from {
        transform: translateX(-50px);
      }
    
      to {
        transform: translateX(0px);
      }
}
