.single-hotel{
    margin-top: 150px;
    padding-inline:5%;
}
.hotel-filters-single{
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #E1E1E1;
    width: 30%;
}
.search-flex{
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #e1e1e1;
    padding-top: 2%;
}
.search-flex:last-child{
    padding-top: 5%;
    padding-bottom: 5%;
}
.search-image{
    width: 32px;
    height: 32px;
    margin-right:20px;
}
.search-text{
    margin-left: 20px;
}
.button-filter{
    width: 70%;
    color: #fff;
    background-color: #75C19F;
    border-radius: 38px;
    border: none;
    height: 40px;
    margin-inline:15%;
    font-size: 18px;
}
.single-hotel-images{
    width: 80%;
    margin-left: 5%;
}
.logopatext-vertical{
    width: 60px;
    height: 60px;
}
.hotel-place{
    display: flex;
    justify-content: space-between;
    width: 95%;
    align-items:center;
}
.filter-images{
    display: flex;
}
.yjet{
    width: 128px;
}
.ratingat{
    width: 32px;
}
.how-far{
    display: flex;
    justify-content: space-between;
}
.how-far-paragraph{
     margin-left: 20px;
}
.how-good{
    display: flex;
    justify-content: space-between;
    width: 20%;
    align-items: center;
}
.nameof-hotel{
    color: #000;
    font-family: "Inter Bold";
    font-size: 28px;
}
.big-image{
    display: flex;
    justify-content: left;
}
.main-img{
    width: 70%;
}
.hotel-description{
    font-size: 16px;
    font-family: "Inter Light";
    color: #000;
    width: 95%;
}
.main-img img{
    width: 100%;
    height: 480px;
}
.small-images{
    width: 30%;
    padding: 0;
    margin-left: 1%;
}
.small-images img{
    width: 80%;
    margin:0;
    height: 150px;
    margin-top: 10px;
}
.small-images img:first-child{
    margin-top: 0;
}
table.GeneratedTable {
    width: 95%;
    background-color: #ffffff;
    border-collapse: collapse;
    border-width: 2px;
    border-color: #c8c8c8;
    border-style: solid;
    color: #000000;
  }
  
  table.GeneratedTable td, table.GeneratedTable th {
    border-width: 2px;
    border-color: #c8c8c8;
    border-style: solid;
    padding: 3px;
   
  }
  
  table.GeneratedTable thead {
    background-color: #1f8896;
  }
  th{
    height: 40px;
    color: #fff;
    font-size: 20px;
  }
  td{
    width: 20%;
    text-align: left;
  }
  .section-heading{
    color: #1E1E1E;
    font-size: 28px;
    font-family: "Inter Semi Bold";
  }
  .table-flex{
    display: flex;
    align-items: center;
    justify-content: left;
    .table-header{
        margin-left: 10px;
    }
  }
  .table-top{
    vertical-align: top;
  }
  .table-mid{
    vertical-align: middle;
    text-align: center;
  }

  .table-paragraph{
    font-size: 14px;
    font-family: "Inter Regular";
    color: #848484;
  }
  .table-header{
    font-size: 14px;
    color: #1E1E1E;
    font-family: "Inter Semi Bold";
  }
  .table-price{
    color: #218796;
    font-size: 14px;
    font-family: "Inter Bold";
    margin-left: 10px;
  }
  
.hotel-single-page{
    width: 31%;
    margin-left: 1%;
}
.hotel-img{
    width: 100%;
}
.yjet{
    height: 20px;
}
.reviewsat{
    display: flex;
    justify-content: space-between;
    height: 32px;
    width: 100%;
}
.ratingat{
    width: 32px;
    height: 32px;
}.hotel-name{
    font-family: "Inter Semi Bold";
    font-size: 18px;
    color: #000;
    margin-top: 0px;
    font-weight: 700;
}
.hotel-info-wrap{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: -25px;
}
.hotel-single-wrap{
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}
.hotel-info{
    font-size: 16px;
    font-family: "Inter Light";
    color: #868686;

}
@media screen and (max-width: 768px){
    .hotels{
        display: block;
    }
    .hotel{
        width: 100%;

    }
    table.GeneratedTable{
        display: none;
    }
    .hotel-filters-single{
        display: block;
        position: relative;
        width: 89%;
        padding-inline: 5%;
    }
    .hotel-place{
        display: none;
    }
    .big-image{
        display: block;
    }
    .single-hotel{
        padding-inline: 0;
    }
    .main-img {
        width: 100%;

        img{
            width: 115%;
            height: auto;
        }
    }
    .small-images{
        width: 115%;    }
    .small-images img{
        width: 100%;
        object-fit: cover;

    }
    .section-heading{
        padding-left: 5%;
    }
   .nameof-hotel {
        padding-left: 5%;
    }
    .hotel-description {
        padding-left: 5%;
    }
    .single-hotel{
        display: block;
        margin-top: 100px;
    }
    .filter-images{
        display: block;
    }
}