.w-full{
    width: 100%;
}

.border-none{
    border: none;
}

.my-10{
    margin: 10px 0 !important;
}
.mr-10{
    margin-right: 10px !important;
}
.my-20{
    margin: 20px 0 !important;
}
