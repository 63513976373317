body {
  margin: 0;
  font-family: "Inter Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;

}
@font-face {
  font-family: "Inter Regular";
  src: url("/src/assets/fonts/Inter-Regular.ttf");
}
@font-face{
  font-family: "Inter Bold";
  src: url("/src/assets/fonts/Inter-Bold.ttf");
}
@font-face{
  font-family: "Inter Medium";
  src: url("/src/assets/fonts/Inter-Medium.ttf");
}
@font-face{
  font-family: "Inter Semi Bold";
  src: url("/src/assets/fonts/Inter-SemiBold.ttf");
}
@font-face {
  font-family: "Displace Regular";
  src: url("/src/assets/fonts/Displace-Reg.ttf");
}
@font-face {
  font-family: "Inter Light";
  src: url('/src/assets/fonts/Inter-Light.ttf');
}
code {
  
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
